import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const NotificationState = {
  getNotification: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/admin/get-notification`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  deleteNotification: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-notification/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },
};

export default NotificationState;
