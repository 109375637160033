import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const VisitorState = {
  addVisitor: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/visitor/usersite/create-profile`,
      requestOptions,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  getVisitor: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/visitor/usersite/get-profile`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  getVisitorContact: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/visitor/get-data`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  getVisitorById: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/visitor/usersite/get-profile-byId/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  getCouponById: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/visitor/usersite/get-coupon-byId/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  visitorSendOtp: async (requestOptions) => {
    // console.log("requestOptions", requestOptions);

    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/visitor/usersite/send-otp`,
      requestOptions,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response-state", response);
    let datas = response.data;
    // console.log("datas", datas);
    return datas;
  },

  visitorVerifyOtp: async (requestOptions) => {
    // console.log("requestOptions", requestOptions);

    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/visitor/usersite/verify-otp`,
      requestOptions,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data;
    // console.log("datas", datas);
    return datas;
  },

  visitorMpin: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/visitor/usersite/generate-mpin`,
      requestOptions,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data;
    // console.log("datas", datas);
    return datas;
  },

  visitorLogin: async (requestOptions) => {
    // console.log("requestOptions", requestOptions);

    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/visitor/usersite/login-profile`,
      requestOptions,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("login-response", response);
    let datas = response.data;
    // console.log("login-datas", datas);
    return datas;
  },

  deleteVisitor: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/visitor/usersite/delete-profile/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  deleteVisitorContact: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/visitor/delete-data/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updateVisitor: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/visitor/usersite/update-profile/${id}`,
      requestOptions,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      }
    );
  },

  visitorForgotMpin: async (requestOptions) => {
    // console.log("requestOptions", requestOptions);

    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/visitor/usersite/forgot-mpin`,
      requestOptions,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response-state", response);
    let datas = response.data;
    // console.log("datas", datas);
    return datas;
  },
};

export default VisitorState;
