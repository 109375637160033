import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const ContactState = {
  addContact: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-contact`,
      requestOptions,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    return datas;
  },

  getContact: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/admin/get-contact`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    //console.log("response", response);
    let datas = response.data.data;
    //console.log("datas", datas);
    return datas;
  },

  deleteContact: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-contact/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updateContact: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-contact/${id}`,
      requestOptions
    );
  },
};

export default ContactState;
