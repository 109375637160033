import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/HomeSection.css";
import ServiceProvider from "./components/Common/ContextApi/ServiceProvider";
import { PWAInstallProvider } from "./components/Common/ContextApi/PWAInstallContext";

// import { register } from "./serviceWorkerRegistration";
// or
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ServiceProvider>
    <PWAInstallProvider>
      <App />
    </PWAInstallProvider>
  </ServiceProvider>
);

// Register the service worker
// register();
// or

// serviceWorkerRegistration.register();
