import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const AdminState = {
  addUsers: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-profile`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  getUsers: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/admin/get-profile`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    //console.log("response", response);
    let datas = response.data.user;
    //console.log("datas", datas);
    return datas;
  },

  deleteUsers: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-profile/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updateUsers: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-profile/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },

  updateUserStatus: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.post(
      `${BASE_URL}/api/v1/admin/update-status/${id}`,
      requestOptions,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
  },
};

export default AdminState;
