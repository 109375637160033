import React, { createContext, useContext, useState, useEffect } from "react";

const PWAInstallContext = createContext();

export const PWAInstallProvider = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsPromptVisible(true);
    };

    // window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    const isMobile = /iPad|iPhone|iPod|Android/i.test(navigator.userAgent);
    const isWindows = /Win/i.test(navigator.userAgent);
    const isMac = /Mac/i.test(navigator.userAgent);

    if (isMobile || isWindows || isMac) {
      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    }

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setIsPromptVisible(false);
      });
    }
  };

  return (
    <PWAInstallContext.Provider value={{ isPromptVisible, handleInstallClick }}>
      {children}
    </PWAInstallContext.Provider>
  );
};

export const usePWAInstall = () => useContext(PWAInstallContext);
